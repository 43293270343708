<template>
  <div>
    <new-lead-sidebar />
    <div style="position: relative">
      <app-breadcrumb :breadcrumbTitle="false">
        <b-button
          variant="primary"
          @click="openNewLeadSidebar(undefined)"
          style="position: absolute; right: 13"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Novo lead PN</span>
        </b-button>
      </app-breadcrumb>
    </div>
    <div class="d-md-flex container-card-statistics">
      <b-card no-body class="mr-md-2 card-origin">
        <b-card-header class="p-2">
          <b-card-title>Origem</b-card-title>
        </b-card-header>
        <b-card-body class="px-3">
          <b-row class="grid-origin">
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <div class="circle total_leads">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_all_leads.svg"
                    />
                  </div>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.total_leads_pn }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Total leads
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="filterOrigin([leads.OWN_CONTACT])"
                  >
                    <div class="circle total_own">
                      <feather-icon icon="UsersIcon" size="30" />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.own_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Contato próprio
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="filterOrigin([leads.REC_PN])"
                  >
                    <div class="circle total_rec">
                      <feather-icon icon="UserPlusIcon" size="30" />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.rec_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0"> Rec PN </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="
                      filterOrigin([
                        leads.CV_CATHO,
                        leads.CV_LINKEDIN,
                        leads.CV_OTHERS,
                      ])
                    "
                  >
                    <div class="circle total_cv">
                      <img
                        class="img_icon"
                        src="~@/assets/images/svg/icn_cv.svg"
                      />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.cv_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0"> CV </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card no-body class="card-status">
        <b-card-header class="p-2">
          <b-card-title>Status</b-card-title>
        </b-card-header>
        <b-card-body class="px-3">
          <b-row class="grid-status">
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="
                      filterStatus(status.CUSTOMER_STATUS_LEAD_PN_UNDEFINED)
                    "
                  >
                    <div class="circle total_unclassified">
                      <feather-icon icon="MoreHorizontalIcon" size="30" />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.unclassified_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    A classificar
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="filterStatus(status.CUSTOMER_STATUS_LEAD_PN_HOT)"
                  >
                    <div class="circle total_hot">
                      <img
                        class="img_icon"
                        src="~@/assets/images/svg/icn_hot_active.svg"
                      />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.hot_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0"> Quente </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="filterStatus(status.CUSTOMER_STATUS_LEAD_PN_COLD)"
                  >
                    <div class="circle total_cold">
                      <img
                        class="img_icon"
                        src="~@/assets/images/svg/icn_cold_active.svg"
                      />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.cold_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0"> Frio </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="
                      filterStatus(status.CUSTOMER_STATUS_LEAD_PN_DISINTERESTED)
                    "
                  >
                    <div class="circle total_disinterested">
                      <img
                        class="img_icon"
                        src="~@/assets/images/svg/icn_disinterested_active.svg"
                      />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.uninterested_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Desinteressado
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="
                      filterStatus(status.CUSTOMER_STATUS_LEAD_PN_SOLIDES)
                    "
                  >
                    <div class="circle total_solides">
                      <img
                        class="img_icon"
                        src="~@/assets/images/svg/icn_solides.svg"
                        width="15"
                        style="transform: scale(2.2)"
                      />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.solides_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0"> Solides </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="
                      filterStatus(status.CUSTOMER_STATUS_LEAD_PN_DISQUALIFIED)
                    "
                  >
                    <div class="circle total_desqualified">
                      <feather-icon icon="XIcon" size="30" />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.disqualified_leads_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Desclassificado
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="auto" class="mt-1">
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-button
                    class="btn-icon"
                    variant="flat"
                    style="padding: 0"
                    @click="
                      filterStatus(status.CUSTOMER_STATUS_LEAD_PN_TRANSFORMED)
                    "
                  >
                    <div class="circle total_turned">
                      <feather-icon icon="RefreshCwIcon" size="30" />
                    </div>
                  </b-button>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ totalizer.leads_pn_turned_to_pn || 0 }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Transformado em PN
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BPopover,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import * as customerStatus from "@/constants/customers_status";
import * as leads from "@/constants/lead";
import NewLeadSidebar from "../components/NewLeadSidebar";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
    NewLeadSidebar,
    AppBreadcrumb,
  },
  props: {
    totalizer: {
      type: Object,
      default: undefined,
    },
    filterStatus: Function,
    filterOrigin: Function,
    openNewLeadSidebar: Function,
  },
  computed: {
    status: function () {
      return customerStatus;
    },
    leads: function () {
      return leads;
    },
  },
};
</script>

<style lang="scss">
.total_leads {
  background-color: #22505f;
}
.total_hot {
  background-color: #492f3a;
}
.total_cold {
  background-color: #223f61;
}
.total_disinterested {
  background-color: #48453b;
}
.total_solides {
  background-color: #583f99;
}
.total_desqualified {
  color: #f26237;
  background-color: #654042;
}
.total_unclassified {
  background-color: #3d3d12;
  color: #ffff00;
}
.total_cv {
  color: #0a66c2;
  background-color: rgba(10, 102, 194, 0.3);

  img {
    filter: invert(27%) sepia(98%) saturate(1057%) hue-rotate(187deg)
      brightness(99%) contrast(104%);
  }
}
.total_rec {
  background-color: #23502d;
  color: #3eb520;
}
.total_own {
  color: #fff;
  background-color: #505360;
}
.total_turned {
  background-color: #493371;
  color: #c67ee2;
}
.circle {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 100%;
}
.img_icon {
  width: 30px;
  height: 30px;
}
#buttons {
  border: 0px;
}

.grid-origin {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;

  @media (max-width: 986px) {
    & {
      grid-template-columns: 1fr !important;
    }
  }

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr 1fr !important;
    }
  }
}

.grid-status {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1455px) {
    & {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }

  @media (max-width: 986px) {
    & {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr 1fr !important;
    }
  }
}

.card-origin {
  width: 33.3%;

  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
}

.card-status {
  width: 66.6%;

  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
}

// @include media-breakpoint-down(sm) {
//   .grid-mobile {
//     display: grid !important;
//     grid-template-columns: 1fr 1fr !important;
//   }
// }
</style>
